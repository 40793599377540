import React from 'react';
import PropTypes from 'prop-types';
import CollapsibleText from '../CollapsibleText';

const DynamicCollapsibleText = ({ blok }) => <CollapsibleText {...blok} />;

DynamicCollapsibleText.propTypes = {
  blok: PropTypes.PropTypes.object.isRequired,
};

export default DynamicCollapsibleText;

import React, { useState, memo } from 'react';
import PropTypes from 'prop-types';
import { Box, Heading } from 'grommet';
import { m, LazyMotion } from 'framer-motion';

import useRichText from './useRichText';

const loadFeatures = () =>
  import('../lib/framer/motionFeatures.js').then((res) => res.default);

const CollapsibleText = ({
  title,
  body,
  titleSize = 'medium',
  headingLevel,
  headingStyle,
}) => {
  const [open, setOpen] = useState(false);
  const isNormalCase = headingStyle === 'normal';
  const richText = useRichText(body);
  return (
    <LazyMotion strict features={loadFeatures}>
      <Box>
        <Heading
          style={{
            cursor: 'pointer',
            textDecoration: open ? 'underline' : 'none',
            textTransform: isNormalCase ? 'none' : 'uppercase',
          }}
          level={headingLevel}
          onClick={() => setOpen(!open)}
          size={titleSize}
        >
          {title}
        </Heading>
        <m.div
          style={{ overflow: 'hidden' }}
          animate={{ height: open ? 'auto' : 0 }}
          initial={{ height: 0 }}
        >
          {richText}
        </m.div>
      </Box>
    </LazyMotion>
  );
};

CollapsibleText.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  titleSize: PropTypes.string,
  headingLevel: PropTypes.oneOf([
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    1,
    2,
    3,
    4,
    5,
    6,
  ]),
  headingStyle: PropTypes.oneOf(['normal', 'capitalized']),
};

export default memo(CollapsibleText);
